import MaisAtacado from "./MaisAtacado";
import qs from "qs";

const getBoletos = (clientes,filter, { sz, pg, order }) =>
  MaisAtacado.post(`/v1/boleto?${qs.stringify({ sz, pg, order, ...filter })}`, clientes);

const getBoletoPdf = async (boleto) => {
  await MaisAtacado.get(`/v1/boleto/pdf?${qs.stringify(boleto)}`).then(
    (response) => {
      var tempElement = document.createElement("div");
      tempElement.innerHTML = response.data;
      var link = tempElement.querySelector("a").getAttribute("href");
      window.open(link);
    }
  );
};

const Boleto = { getBoletoPdf, getBoletos};
export default Boleto;
