import React from "react";
import { useLocation } from "react-router-dom";
import FullPage from "../../components/FullPage";
import BemVindo from "../../components/BemVindo";
import Afiliados from "./Section/Afiliados";
// import Material from "./Section/Material";
import useAffiliate from "../../Hooks/AffiliateContext";
import CadastroMM from "./Section/CadastroMM";
import MinhasVendas from "./Section/MinhasVendas";
import VisaoVendas from "./Section/VisaoVendas";
import SaibaMais from "./Section/SaibaMais";

const AfiliadoPage = (props) => {
  const location = useLocation();
  let path = location.pathname

  const { affiliate, acceptedNewContract } = useAffiliate();

  const isAffiliated = affiliate ? true : false;
  const isToHidde = !acceptedNewContract;

  let config = {
    parentUrl: path,
    parentName: "AFILIADOS",
    parentComponent: BemVindo,
    routes: [
      {
        route: "cadastro",
        text: "CADASTRAR",
        component: CadastroMM,
        show: !isAffiliated,
      },
      {
        route: "meu-cadastro",
        text: "MEU CADASTRO",
        component: CadastroMM,
        show: isAffiliated,
      },
      {
        route: "afiliados",
        text: "MEUS VENDEDORES",
        component: Afiliados,
        show: isToHidde || isAffiliated,
      },
      {
        route: "minhas-vendas",
        text: "MINHAS VENDAS",
        component: MinhasVendas,
        show: isAffiliated,
      },
      {
        route: "visao-vendas",
        text: "VENDAS AFILIADOS",
        component: VisaoVendas,
        show: "repre",
      },
      {
        route: "saiba-mais",
        text: "SAIBA MAIS",
        component: SaibaMais,
        show: "admin",
      },
    ],
  };

  return <FullPage {...config} />;
};

export default AfiliadoPage;
