import React from 'react'
import { Link } from 'react-router-dom'

import s from './DetailsBox.module.css'

import TableItems from './table/TableItems'
import HeaderItems from './header/HeaderItems'
import SeparateLine from '../SeparateLine/SeparateLine'
import SecondaryButton from '../SecondaryButton/SecondaryButton'

const DetailsItem = (props) => {
    const {
        title,
        blocksInfos,
        warning,
        grid,
        divergency,
        gridReducer,
        editItemButton,
        isSearchPage,
        componentProps
    } = props

    const table = {
        isEditItem: gridReducer,
        isSearchPage,
        grid: grid?.grid,
        divergency: divergency ? divergency.grid : null,
        gridReducer
    }

    return (
        <section id={title.id} className={`${s.itensInfo} ${s[componentProps?.additionalClassName]}`}>
            <div className={s.titleIcon}>
                {title?.Icons?.map((Icon, i) => (<React.Fragment key={i}>{Icon}</React.Fragment>))}
            </div>
            <div className={s.itemTitle}>
                <h5>{title.id} - {title.description}</h5>

            </div>

            {Object.entries(blocksInfos).map(([k, v]) => (<HeaderItems key={k} items={v} />))}
            <SeparateLine />
            {grid && <TableItems table={table} />}

            {warning && <p {...warning?.componentProps}>
                {warning.message}
                <Link to={warning?.link?.link || '.'}><span onClick={warning?.link?.click}>{warning?.link?.text}</span></Link>
            </p>}

            {
                editItemButton &&
                <div className={`${s.divergencyButton} ${s[props?.editItemButton?.componentProps?.additionalClassName]}`}>
                    <SecondaryButton onClick={editItemButton?.click} additionalClassName="button__SecondaryButton--conteiner_inherit">
                        <Link className={s.divergencyButtonContent} to={editItemButton?.link || '#'}>
                            {editItemButton?.Icon}
                            <span>{editItemButton?.text}</span>
                        </Link>
                    </SecondaryButton>
                </div>
            }
        </section >

    )
}

export default DetailsItem