import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import background from "../assets/images/Background/backgroundEtiquetaDevolucao.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const convertToBase64 = async (selectedFile) => {
    try {
        const blob = await (await fetch(selectedFile)).blob();
        return await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Erro ao converter arquivo para Blob:', error);
        return null;
    }
}

const makePdf = async  (remetente, entrega) => {   
    const logoDataUrl = await convertToBase64(background);
    const CNPJFormated = remetente.CGC_CPF.trim().length === 14 ?
        remetente.CGC_CPF.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') :
        remetente.CGC_CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    const leftModel = [
        {
            text: entrega,
            absolutePosition: {x: 109, y: 44},
            style: 'embarque'
        },
        {
            text: remetente.RAZAO_SOCIAL,
            absolutePosition: {x: 92, y: 111.5},
            style: 'remetente'
        },
        {
            text: CNPJFormated,
            absolutePosition: {x: 57, y: 127.5},
            style: 'dadosRemetente'
        },
        {
            text: remetente.ENDERECO,
            absolutePosition: {x: 84, y: 142.5},
            style: 'dadosRemetente'
        },
        {
            text: remetente.BAIRRO,
            absolutePosition: {x: 70, y: 157.5},
            style: 'dadosRemetente'
        },
        {
            text: remetente.CIDADE,
            absolutePosition: {x: 70, y: 172.5},
            style: 'dadosRemetente'
        },
        {
            text: remetente.UF,
            absolutePosition: {x: 47, y: 188},
            style: 'dadosRemetente'
        },
    ];

    const rightModel = [
        {
            text: entrega,
            absolutePosition: {x: 515, y: 42.5},
            style: 'embarque'
        },
        {
            text: remetente.RAZAO_SOCIAL,
            absolutePosition: {x: 498, y: 110},
            style: 'remetente'
        },
        {
            text: CNPJFormated,
            absolutePosition: {x: 463, y: 125},
            style: 'dadosRemetente'
        },
        {
            text: remetente.ENDERECO,
            absolutePosition: {x: 489, y: 140.5},
            style: 'dadosRemetente'
        },
        {
            text: remetente.BAIRRO,
            absolutePosition: {x: 475, y: 155},
            style: 'dadosRemetente'
        },
        {
            text: remetente.CIDADE,
            absolutePosition: {x: 475, y: 171},
            style: 'dadosRemetente'
        },
        {
            text: remetente.UF,
            absolutePosition: {x: 452, y: 186},
            style: 'dadosRemetente'
        },
    ];

    const docDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [0, 0, 0, 0],
        content: [{ image: logoDataUrl, width: 840 }, leftModel, rightModel],
        styles: {
            embarque: {
                fontSize: 12,
                bold: true,
            },
            remetente: {
                fontSize: 10,
                bold: true
            },
            dadosRemetente: {
                fontSize: 10,
            },
        }
    }

    pdfMake.createPdf(docDefinition).download('Etiqueta_Devolução');

}

export default makePdf
