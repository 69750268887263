import React, { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

import SomaStatus from '../../../components/SomaStandard/SomaStatus'
import ItemsRow from '../../../components/ItemsRow/ItemsRow';

import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import svg from './image.svg'
import { authFetch } from '../../../helpers/authHelper';
import MaisAtacado from '../../../Services/MaisAtacado/MaisAtacado';
import api from '../../../helpers/apiHelper';

const SaibaMais = () => {
    const [results, setResults] = useState([])
    const [status, setStatus] = useState([])

    const downloadBtn = async (material) => {
        let file
        if (material.infos.type === 'link') {
            return
        } else {
            file = await api(() => MaisAtacado.get(`/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}&type=${encodeURI(material.infos.type)}`
                , { responseType: 'blob' }
            ))
            if (file.error) return

            file = URL.createObjectURL(file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.download = material.idArquivo;
        a.click();
    }

    const openBtn = async (material) => {
        let file
        if (material.infos.type === 'link') {
            file = material.idArquivo
        }
        else {
            file = await api(() => MaisAtacado.get(`/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}&type=${encodeURI(material.infos.type)}`
                , { responseType: 'blob' }
            ))
            if (file.error) return
            file = URL.createObjectURL(await file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.target = '_blank';
        a.click();
    }

    const descriptionItem = () => {
        return <>
            <p style={{ color: '#000000' }}>
                Estamos muito felizes que você veio  somar ao Grupo Soma e nos ajudar a
                compartilhar itens lindos e incríveis.  Neste material, separamos as principais
                informações para te ajudar nas suas  vendas digitais e garantir muito sucesso.
            </p>
            <p>
                Caso não encontre alguma informação  para o seu trabalho, não se preocupe! É só
                entrar em contato com o nosso  SAC através dos nossos canais e te  ajudaremos, ok?
            </p>
            <p style={{ fontSize: '12px' }}>
                <span style={{ marginRight: window.innerWidth > 480 ? '20px' : '0', display: window.innerWidth > 480 ? 'inline' : 'block' }}>
                    <EmailIcon fontSize='small' />
                    <span style={{ verticalAlign: 'super', marginLeft: '3px' }}>atendimentomultimarcas@somagrupo.com.br</span>
                </span>
                <span>
                    <LocalPhoneIcon fontSize='small' />
                    <span style={{ verticalAlign: 'super', marginLeft: '3px' }}>+55 21 3733-7708</span>
                </span>
            </p >
        </>
    }

    useEffect(() => {
        setStatus('wait');
        (async () => {
            try {
                const compararDatas = (date) => differenceInDays(new Date(), new Date(date))

                const json = await (await authFetch(`/v1/material/`)).json();

                Object.entries(json).forEach(([titulo]) => {
                    json[titulo].forEach((material, index) => {
                        json[titulo][index] = {
                            ...material,
                            description: descriptionItem(),
                            tag: {
                                name: 'Novo',
                                color: '#C4A9FF',
                                Show: compararDatas(material.createdAt) < 7
                            },
                            leftBtn: {
                                onClick: () => openBtn(material),
                                name: 'ABRIR'
                            },
                            rightBtn: {
                                onClick: () => downloadBtn(material),
                                name: 'DOWNLOAD',
                                disabled: material.infos.type === 'link'
                            }
                        }
                    })
                });

                setResults(json);
                setStatus(Object.keys(json)?.length ? 'result' : 'empty');
            } catch (err) {
                setResults(undefined);
                setStatus('error');
            }
        })();
    }, []);

    return (
        <SomaStatus status={status}>
            {
                Object.entries(results).map(([key, value]) => {
                    return (<ItemsRow key={key} widthCard={184} title={key} items={value} />)
                })
            }
        </SomaStatus>
    )
}

export default SaibaMais;