import React from 'react';
import { v4 } from "uuid";

import './SomaDataOverview.css';

const SomaDataOverview = ({data, title}) => {
    
    const datas = data?.map((d)=>{
        return(
            <div className='div__somadataoverview--container' key={v4()}>
                <p className='div__somadataoverview--title'>{d.title}</p>
                <p className='div__somadataoverview--subtitle'>{d.subtitle}</p>
                <div className='div__somadataoverview--values-container'>
                    <p className='div__somadataoverview--value-symbol' style={{color: d.color}}>R$</p>
                    <p className='div__somadataoverview--value' style={{color: d.color}}>{d.value?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</p>
                </div>
            </div>
        )
    })
  
    return (
        <div>
            <p className='div__somadataoverview--text'>{title}</p>
            <div className='div__somadataoverview'>
                {datas?.map(d=> d)}
            </div>
        </div>
    );
};

export default SomaDataOverview;
