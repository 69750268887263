import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import { encodeFilter } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import ConverteData from '../../../components/SomaStandard/TableComponents/ConverteData';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import TableSettings from '../../../components/TableSettings/TableSettings';



const FaturasInadimplentes = (props) => {
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);


  const FilterOpts = {
    filterFields: [
      { propName: "VENCIMENTO_REAL", component: ToFrom, propText: "Data", componentProps: { component: DatePicker } },
      { propName: "NOME_CLIFOR[lk]", propText: "Cliente", format: val => `%${val}%` },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  const Opts = {
    endpoint: "/v1/FaturasInadimplentes/faturasInadimplentes",
    columns: [
      {
        propName: "Faturas Inadimplentes",
        componentProps: {
          colunas: [
            { propName: "FATURA", propText: "Fatura", highlighted: true },
            { propName: "NOME_CLIFOR", propText: "Cliente" },
            { propName: "VENCIMENTO_REAL", propText: "Data de Vencimento", component: ConverteData },
            { propName: "VALOR_ORIGINAL", propText: "Valor Original", component: ConverteDinheiro },
            { propName: "VALOR_A_RECEBER", propText: "Valor a Pagar", component: ConverteDinheiro },
            { propName: "DIAS_ATRASO", propText: "Dias atrasados" }
          ]
        }
      }
    ],
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  useEffect(() => {

    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();

        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
      } catch (err) {
        setResults(undefined);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter]);

  return (
    <>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );
};

export default FaturasInadimplentes;
