import React from 'react'
import s from './Card.module.css'

const CardBox = (props) => {
    const { title, download, infos } = props

    return (
        <section className={s.CardBox} >
            <div className={s.boxDetail} >
                <div className={s.leftSideName} >
                    {title?.Icon}
                    <h4>{title?.name} {title?.id}</h4>
                </div>

                <button onClick={download?.click} className={s.rightSideName} >
                    {download?.Icon}
                    <span>{download?.name}</span>
                </button>
            </div>

            <div className={s.boxInfo}>
                {infos?.map((inf, index) => (
                    <div key={index} className={s.boxInfoItem}>
                        {inf?.left &&
                            <div className={`${s[inf.componentProps?.addClassName]}`}>
                                {inf.left}
                            </div>
                        }
                        {inf?.right &&
                            <div className={`${s[inf.componentProps?.addClassName]}`}>
                                {inf.right}
                            </div>
                        }
                    </div>
                ))}
            </div>

        </section>
    )
}

export default CardBox