import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

import useUser from "../../../Hooks/UserContext";
import useAffiliate from "../../../Hooks/AffiliateContext";

import HomeIcon from "../../../assets/images/icons/home_black.svg";
import FinanceiroIcon from "../../../assets/images/icons/cartao.svg";
import ShowroomIcon from "../../../assets/images/icons/caixa.svg";
import AfiliadosIcon from "../../../assets/images/icons/team.svg";
import iconMaisAtacado from "../../../assets/images/logos/logoAtacado.svg";

import SideMenuListItem from "../SideMenuListItem/SideMenuListItem";

import "./SideMenuListItems.css";

const isDefined = (value) => value !== undefined && value !== null;

const SideMenuListItems = (props) => {
  const user = useUser();
  const { acceptedNewContract, affiliate } = useAffiliate();
  const { state, setState, isMenuVisible, shouldShow } = props;

  const menuConfigItems = useMemo(() => {
    return [
      {
        title: "admin",
        onClick: () => setState("admin"),
        show: user.features?.admin === true,
        open: state.admin,
        items: [
          { name: "IMPERSONATE", url: "/Admin/impersonate", showTo: "admin" },
          {
            name: "MINHAS VENDAS",
            url: "/Admin/minhas-vendas-secreto",
            showTo: "admin",
          },
          {
            name: "DIVERGENCIAS",
            url: "/Admin/divergencias",
            showTo: "tratativa",
          },
          {
            name: "ATUALIZAR CÓDIGO",
            url: "/Admin/update-code",
            showTo: "admin",
          },
          { name: "CODIGO DE BARRAS ", url: "/Admin/barcode", showTo: "admin" },
          { name: "CADASTRO AFILIADO", url: "/Admin/cadastro-afiliado", showTo: "admin" },
          { name: "VENDAS MARCAS", url: "/Admin/vendas-marcas", showTo: "admin" },
        ],
      },
      {
        title: "financeiro",
        onClick: () => setState("financeiro"),
        show: true,
        open: state.financeiro,
        items: [
          { name: "FATURAS", url: "/Financeiro/faturas" },
          { name: "DESCONTOS CONCEDIDOS", url: "/Financeiro/descontos" },
          {
            name: "FATURAS INADIMPLENTES",
            url: "/Financeiro/faturas-inadimplentes",
            showTo: "repre",
          },
        ],
      },
      {
        title: "showroom digital",
        onClick: () => setState("comercial"),
        show: true,
        open: state.comercial,
        items: [
          {
            name: "SEGUNDA VIA DE NOTA FISCAL",
            url: "/Comercial/seg-via-nota-fiscal",
          },
          { name: "MEUS PEDIDOS", url: "/Comercial/meus-pedidos" },
          { name: "BLOQUEIOS", url: "/Comercial/bloqueios" },
          { name: "RECEBIMENTO", url: "/Comercial/recebimento" },
          { name: "METAS", url: "/Comercial/metas", showTo: "repre" },
        ],
      },
      {
        title: "afiliados",
        onClick: () => setState("afiliado"),
        show: true,
        open: state.afiliado,
        items: [
          {
            name: "DADOS CADASTRAIS",
            url: "/Afiliado/meu-cadastro",
            showTo: isDefined(affiliate),
          },
          {
            name: "MEUS VENDEDORES",
            url: "/Afiliado/afiliados",
            showTo: isDefined(affiliate) && acceptedNewContract,
          },
          {
            name: "MINHAS VENDAS",
            url: "/Afiliado/minhas-vendas",
            showTo: isDefined(affiliate),
          },
          {
            name: "SAIBA MAIS",
            url: "/Afiliado/saiba-mais",
            showTo: "admin",
          },
          {
            name: "VENDAS AFILIADOS",
            url: "/Afiliado/visao-vendas",
            showTo: "repre",
          },
          {
            name: "AFILIAR",
            url: "/Afiliado/cadastro",
            showTo: !isDefined(affiliate) && !user.features?.repre,
          },
        ],
      },
    ];
  }, [
    user,
    state.admin,
    state.financeiro,
    state.comercial,
    state.afiliado,
    affiliate,
    acceptedNewContract,
    setState,
  ]);

  return (
    shouldShow &&
    (
      isMenuVisible ?
        <ul className="ul__SideMenuListItems--conteiner">
          <li className="li__logoatacado--conteiner">
            <div>
              <img src={iconMaisAtacado} alt="Logo Mais Atacado" />
            </div>
          </li>
          <li className="li__homeItem--conteiner">
            <NavLink
              exact="true"
              to="/"
              className="a__homeItem--conteiner"
              activeclassname="a__activedhomeItem--conteiner"
            >
              <img src={HomeIcon} alt="Ícone de casa" />
              <span> home </span>
            </NavLink>
          </li>
          {menuConfigItems.map((item) => (
            <SideMenuListItem
              key={item.title}
              onClick={item.onClick}
              data={item}
              show={item.show}
              open={item.open}
            />
          ))}
        </ul>
        :
        <ul className="ul__Imagem--conteiner">
          <li className="li__Imagem--item"
          >
            <NavLink
              exact="true"
              to="/"
              className='image__Item'
              activeclassname='image__activedhomeItem--conteiner'
            >
              <img src={HomeIcon} alt="Ícone de casa" />
            </NavLink>
          </li>
          <li className="li__Imagem--item">
            <NavLink
              exact="true"
              to="/Financeiro/faturas"
              className={`image__Item ${state.financeiro && "image__activedhomeItem--conteiner"}`}
            >
              <img src={FinanceiroIcon} alt="Ícone de faturas" />
            </NavLink>
          </li>
          <li className="li__Imagem--item">
            <NavLink
              exact="true"
              to="/Comercial/seg-via-nota-fiscal"
              className={`image__Item ${state.comercial && "image__activedhomeItem--conteiner"}`}
            >
              <img src={ShowroomIcon} alt="Ícone sacolas de compras" />
            </NavLink>
          </li>
          <li className="li__Imagem--item">
            <NavLink
              exact="true"
              to="/Afiliado/cadastro"
              className={`image__Item ${state.afiliado && "image__activedhomeItem--conteiner"}`}
            >
              <img src={AfiliadosIcon} alt="Ícone de pessoas" />
            </NavLink>
          </li>
        </ul>
    )
  );
};

export default SideMenuListItems;
