import axios from "axios";

export { default as Auth } from "./Auth";
export { default as Faturamento } from "./Faturamento";
export { default as Recebimento } from "./Recebimento";
export { default as Afiliados } from "./Afiliados";
export { default as Boleto } from "./Boleto";
export { default as Tratativas } from "./TratativaDivergencias";

const MaisAtacado = axios.create({
  /// eslint-disable-next-line no-restricted-globals
  // baseURL: `${location.origin}:8080`,
  // baseURL: `http://localhost:8080`,
  baseURL: "https://mais-atacado-backend.somalabs.com.br/",
  transformRequest: [
    (data, headers) => {
      const token = localStorage.getItem("CurUser");
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      } else {
        console.log("Cade o KCT do login");
      }
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

export default MaisAtacado;
